.demo-editor {   
    min-height: 150px !important;
    border: 1px solid #F1F1F1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
    resize: vertical;
}
.public-DraftStyleDefault-ltr
{
    span 
    {
       span {
        font-family: inherit !important;
       }
    }
}
.editor{
    margin-top:0px;
    padding-top:0px;
}

.rdw-fontfamily-optionwrapper {
    width: 155px;
}