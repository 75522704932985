
.margin-left-60
{
  margin-left: 60px;
}
.margin-left-16
{
  margin-left: 16px;
}
.margin-left-103
{
  margin-left: 103px;
}
.margin-left-148
{
  margin-left: 148px;
}

.margin-left-128
{
  margin-left: 128px;
}

.margin-left-104
{
  margin-left: 104px;
}

.margin-left-40
{
  margin-left: 40px;
}

.margin-top-24 {
  margin-top: 2.4rem;
}
