$PrimaryColor: #274463;
$ErrorColor: #a81f00;
$SuccessColor: #00b4a0;
$fontFamily: 'Open Sans', sans-serif !important;
$SecondaryColor: #0047BA;
$disabledColor: #888888;
$WhiteColor: #FFFFFF;
$AdditionalColor: #212529;
$size5: 5px;
$size4: 4px;
$Imp: !important;
