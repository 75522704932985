@import '../../../../Variables.scss';
.mui-custom-form.input-display-lob.with-select
{
  .MuiTextField-root
  {
    width: 145px;
    .MuiInput-input
    {
      width: 100%;
    }
  }
}
.mui-custom-form.input-lob.with-select
{
  .MuiTextField-root
  {
    width: 120px;
    .MuiInput-input
    {
      width: 100%;
    }
  }
}
.mui-custom-form.input-sm6.with-select
{
  .MuiTextField-root
  {
    width: 135px;
    .MuiInput-input
    {
      width: 100%;
    }
  }
}
// .mui-custom-form.input-sm6.with-select
// {
//   .MuiInput-input
//   {
//     width: 145px;
//   }
// }
.mui-custom-form.input-sm6
{
  .MuiInput-input
  {
    width: 150px;
  }
}

.mui-custom-form.input-sm5
{
  .MuiInput-input
  {
    width: 175px;
  }
}

.mui-custom-form.input-ps.with-select
{
  .MuiTextField-root
  {
    width: 130px;
    .MuiInput-input
    {
      width: 100%;
    }
  }
}

.text-align-right{
  text-align: right;
  padding: 10px 15px 0px 0px
}
.ml-80
{
  margin-left: 80px;
}
.text-area-text{
  width: 100%;
  height: auto;
  overflow-wrap: break-word;
  resize: 'auto' ;
}