
.margin-left-60
{
  margin-left: 60px;
}
.margin-left-65
{
  margin-left: 65px;
}
.margin-left-109
{
  margin-left: 109px;
}
.margin-left-148
{
  margin-left: 148px;
}

.margin-left-104
{
  margin-left: 104px;
}
.margin-right-134
{
  margin-right: 134px;
}
.margin-left-40
{
  margin-left: 40px;
}

.margin-top-24 {
  margin-top: 2.4rem;
}
.custom-save-dialog.position-top
{
  .swal2-popup
  {
    top: 60px;
  }
  .swal2-icon.swal2-success
  {
    display: block !important;
  }
}
.input-102
{
  width: 102px;
}

.orField
{
  font-weight: bold;
  margin-left: 72px;
  margin-top: 30px;
}

.margin-left-130
{
  margin-left: 130px
}

.margin-left-50
{
  margin-left: 50px;
}

.margin-left-20
{
  margin-left: 18px;
}