
.margin-left-60
{
  margin-left: 60px;
}
.margin-left-65
{
  margin-left: 65px;
}
.margin-left-140
{
  margin-left: 140px;
}
.margin-left-80
{
  margin-left: 73px;
}
.margin-left-70
{
  margin-left: 68px;
}
.margin-left-71
{
  margin-left: 71px;
}
.margin-left-55
{
  margin-left: 55px;
}

.margin-left-16
{
  margin-left: 16px;
}
.margin-right-134
{
  margin-right: 134px;
}
.margin-left-40
{
  margin-left: 40px;
}
.margin-left-45
{
  margin-left: 48px;
}

.margin-top-24 {
  margin-top: 2.4rem;
}
.custom-save-dialog.position-top
{
  .swal2-popup
  {
    top: 60px;
  }
  .swal2-icon.swal2-success
  {
    display: block !important;
  }
}
.input-102
{
  width: 102px;
}

.orField
{
  font-weight: bold;
  margin-left: 72px;
  margin-top: 30px;
}

.margin-left-130
{
  margin-left: 130px
}

.margin-left-50
{
  margin-left: 50px;
}

.margin-left-33
{
  margin-left: 33px;
}

.margin-left-105
{
  margin-left: 106px;
}

.margin-left-108
{
  margin-left: 110px;
}

.margin-left-95
{
  margin-left: 91px;
}