@import './Variables.scss';

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&amp;display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800&amp;display=swap');

@font-face {
  font-family: 'FontAwesomeIcon';
  src: url('./fonts/fontawesome-webfont.eot?v=4.7.0');
  src: url('./fonts/fontawesome-webfont.eot?#iefix&v=4.7.0')
      format('embedded-opentype'),
    url('./fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),
    url('./fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'),
    url('./fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'),
    url('./fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  font-family: 'Open Sans', sans-serif, Arial;
}

html,
body {
  font-size: 14px;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.025em;
}

a,
table tr td a {
  color: $PrimaryColor !important;
  text-decoration: underline !important;
}

/*--Mixins Starts Here--*/
@mixin border-radius($val) {
    border-radius: $val;
    -webkit-border-radius: $val;
    -ms-border-radius: $val;
  }
  @mixin boxShadow($val) {
    box-shadow: $val;
    -webkit-box-shadow: $val;
    -ms-box-shadow: $val;
  }
  @mixin animate-css($props) {
    transition: $props !important;
    -webkit-transition: $props !important;
    -moz-transition: $props !important;
  }
.sm-break-div,
.line-break-div,
.md-break-div {
  visibility: hidden;
  height: 1px;
  clear: both;
  flex-basis: 100%;
  width: 100%;
}

.form-wrap-inner,
.tbody-space,
.container-space {
  padding: 0 14px 1rem !important;
}
  /*--|--*/
  .content-wrapper {
    @include boxShadow(0 10px 40px rgba(24, 39, 56, 0.11));
    padding: 0px 15px 15px !important;
    #mainContentHeader{
      margin-right: -30px;
      margin-left: -30px;
    }
  }

.tabs-container,
.flex-wrap-child > div:last-child,
.makeStyles-containerFluid-12.container-fluid,
.content-wrapper > div > .container-fluid {
  padding: 0px !important;
}
.search-result{
  font-weight: 600;
  font-size: 1.12em;
  margin-bottom: 0.5rem!important;
}

/*-- Custom alert start --*/
.custom-alert-box {
  .MuiDialog-paper {
    min-width: 400px;
  }
  .MuiDialogContent-root {
    padding-top: 30px !important;
    .MuiTypography-body1 {
      font-size: 1.1rem;
      text-align: center;
      color: #222222;
    }
  }
  .MuiDialogActions-root {
    justify-content: center !important;
    padding-bottom: 30px !important;
    .btn {
        font-size: 0.92rem !important;
        padding: 7px 20px !important;
      }
  }
}
/*-- Custom alert end --*/
.btn-transparent,
.btn-delete,
.btn-view,
.btn-notes,
.btn-reset,
.btn-audit,
.btn-filter-transparent {
  background-color: #fff !important;
  border: solid 1px $PrimaryColor !important;
  color: $PrimaryColor !important;
  &:hover,
  &:focus {
    background-color: #f8f8f8 !important;
  }
}
.btn-success, .btn-save, .btn-add, .btn-update {
  background-color: #006e62 !important;
  border-color: #005A64 !important;
  &:hover, &:focus {
    background-color: #005A64 !important;
  }
}

.visuallyhidden, .scrollDiv {
  border: 0 none;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
  padding: 0 !important;
}

/* Parent CSS */
.custom-page{
    .tabs-container{
        background-color: transparent;
        box-shadow: none;
    }


    /* tab Panel Block starts Here */
    .tab-body,
    .tab-body-bordered {
    color: #000;
    background-color: #fff;
    margin-top: 0.35em;
    @include border-radius(5px);
    }
    .tab-body-bordered {
      border: solid 1px #ececec;
      @include boxShadow(0 2px 10px rgba(22, 38, 56, 0.04));
    }
    /*Panel header section start */
    .tab-header{
      height: auto;
      margin-bottom: 0;
    }
    .tab-header,
    .page-header {
      color: #ffffff !important;
      padding: 8px 0 5px;
      &.flex-theader {
        padding: 7px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .tab-heading,
      .page-heading {
        color: #000000;
        padding: 10px 0 5px;
        font-weight: 600;
        margin-bottom: 0px;
        text-transform: none;
        line-height: 1.4;
      }
      .txt-instruction {
        color: #555555;
        font-size: 0.95em;
      }
      .page-heading {
        font-size: 1.28rem!important;
      }
      .th-btnGroup {
        .btn {
          margin: 3px 0 0 10px;
        }
      }
      .float-right {
        .btn {
          margin-top: 3px;
          margin-bottom: 3px;
        }
      }
    }
    /*Panel header section start */

    /*--Custom Tabber Starts Here--*/
    .custom-tabber .ic-group {
        position: absolute;
        top: 8px;
        right: 15px;
        z-index: 11;
        & + .MuiAppBar-root {
            padding-right: 120px;
            border-bottom: solid 2px #cc0000;
            .MuiTabs-root {
            border: none;
            }
        }
    }

    .custom-tabber {
        & .MuiTab-wrapper {
        font-weight: 600;
        line-height: 1;
        font-size: 1.25em;
        letter-spacing: normal;
        }
        .Mui-selected {
        color: $PrimaryColor !important;
        font-weight: 700 !important;
        padding: 6px 12px !important;
        }
        .MuiTab-root {
        text-transform: capitalize !important;
        min-width: 120px !important;
        padding: 6px 12px !important;
        &:focus {
            outline: none;
            border: none;
        }
        }
        .MuiAppBar-colorDefault {
        background: none;
        box-shadow: none;
        font-weight: 600;
        padding: 0 12px;
        .MuiTabs-root {
            border-bottom: solid 1px #aaafb9;
        }
        }
    }
    .fit-tab-1 {
        .MuiTab-root {
        max-width: none;
        }
    }

    .MuiTabs-indicator {
        background-color: $PrimaryColor !important;
    }

    /*--Custom Tabber ends Here--*/
    .tab-holder {
        padding: 0px;
        & > .MuiTypography-body1 {
        padding: 0 12px;
        @include border-radius(0px 0px 4px 4px);
        border-top: 0;
        }
        .MuiBox-root {
        padding: 0px;
        }
    }
    .tab-holder.p-20,
    .wrap-tab-holder .tab-holder {
        padding: 0px 12px !important;
    }

    .MuiTypography-body1 .tabsInner2 {
        padding: 0 !important;
    }
/*--Form wrapper element start Here--*/
  .form-wrapper {
      padding: 8px 3px;
      .mui-custom-form,
      .custom-form-wrapp-inner {
        margin: 8px 10px !important;
        width: calc(25% - 20px);
        &.field-md {
          width: calc(50% - 20px) !important;
          max-width: calc(50% - 20px) !important;
          min-width: calc(50% - 20px) !important;
        }
        &.field-lg {
          width: calc(75% - 20px) !important;
          max-width: calc(75% - 20px) !important;
        }
        &.field-xl {
          width: calc(100% - 20px) !important;
          max-width: calc(100% - 20px) !important;
        }
        @media (max-width: 1200px) {
          .lg-float-right {
            float: right !important;
          }
          &.lg-field-xl {
            width: calc(100% - 20px) !important;
            max-width: calc(100% - 20px) !important;
            -ms-flex: 0 0 calc(100% - 20px) !important;
            flex: 0 0 calc(100% - 20px) !important;
          }
        }
        @media (max-width: 1024px) {
          &.w-100 {
            width: 100% !important;
          }
          &.md-field-md,
          &.md-field-lg {
            width: calc(66.66% - 20px) !important;
            max-width: calc(66.66% - 20px) !important;
            min-width: calc(66.66% - 20px) !important;
            -ms-flex: 0 0 calc(66.66% - 20px) !important;
            flex: 0 0 calc(66.66% - 20px) !important;
          }
          &.md-field-xl,
          &.field-xl {
            width: calc(100% - 20px) !important;
            max-width: calc(100% - 20px) !important;
            -ms-flex: 0 0 calc(100% - 20px) !important;
            flex: 0 0 calc(100% - 20px) !important;
          }
        }
        .clm-inquiry-tabData &:not(.field-xl) {
          @media (max-width: 1200px) {
            width: calc(33.33% - 20px) !important;
            max-width: calc(33.33% - 20px) !important;
            &.lg-field-xl {
              width: calc(100% - 20px) !important;
              max-width: calc(100% - 20px) !important;
              -ms-flex: 0 0 calc(100% - 20px) !important;
              flex: 0 0 calc(100% - 20px) !important;
            }
          }
          @media (max-width: 992px) {
            width: calc(50% - 20px) !important;
            max-width: calc(50% - 20px) !important;
          }
        }
      }
      .mui-custom-form.inner-form-fields,
      .sr-criteria-w1 {
        margin: 0px !important;
      }
      .custom-form-wrapp-inner {
        margin-top: 0 !important;
        .mui-custom-form {
          margin-left: 0 !important;
          width: calc(49.5% - 10px) !important;
        }
        &.flex-inner {
          display: flex;
          -webkit-display: flex;
          justify-content: space-between;
          -webkit-justify-content: space-between;
          .mui-custom-form {
            flex: 0 0 47%;
            -webkit-flex: 0 0 47%;
            width: 47% !important;
            margin-right: 0 !important;
          }
        }
      }
      .mui-custom-form {
        .MuiInputBase-adornedEnd {
          .MuiInputBase-input {
            height: 1.38em;
          }
        }
        .mui-custom-check {
          margin: 2em 0 0;
          .PrivateSwitchBase-root-264,
          .PrivateSwitchBase-root-349,
          .PrivateSwitchBase-root-301,
          .PrivateSwitchBase-root-343 {
            padding: 0 9px;
          }
        }
        label.mui-custom-check {
          .PrivateSwitchBase-root-264 {
            margin-left: -9px;
          }
        }
        .MuiFormGroup-row {
          .MuiFormControlLabel-root {
            margin-bottom: 0;
            .PrivateSwitchBase-root-349,
            .PrivateSwitchBase-root-301 {
              padding: 0px 5px;
            }
          }
        }
      }
      &.form-3-column {
        .mui-custom-form,
        .custom-form-wrapp-inner {
          width: calc(33.333% - 20px) !important;
          max-width: calc(33.33% - 20px) !important;
        }
        .custom-form-wrapp-inner {
          .mui-custom-form {
            width: calc(49.5% - 20px) !important;
            max-width: calc(49.5% - 20px) !important;
            @media (max-width: 768px) {
              width: calc(50% - 10px) !important;
              max-width: calc(50% - 10px) !important;
            }
          }
        }
      }
      .set-form-wrapper & {
        margin: 0 -10px;
        max-width: none;
      }
      .flex-block {
        -webkit-flex-wrap: wrap;
        -ms-flex-flow: row wrap;
        flex-wrap: wrap;
      }
  }
/*--Form wrapper element start Here--*/

/*** Styling Form Elements start ***/
.disable-theme {
  background: #f4f4f4 !important;
  border-color: #eee !important;
}
.mui-custom-form {
  margin: 12px 16px;
  .Mui-error,
  .Mui-required,
  .MuiFormLabel-asterisk {
    color: $ErrorColor !important;
  }
  .Mui-disabled {
    &.MuiInputBase-root {
      @extend .disable-theme;
      pointer-events: none;
    }
  }
  .MuiFormControl-root {
    width: 100% !important;
    max-width: calc(100% - 2px) !important;
  }
  .MuiFormLabel-root,
  .MuiCardHeader-root .MuiTypography-h5 {
    //font-size: 1.22em !important;
    white-space: nowrap;
    color: $PrimaryColor !important;
    font-weight: 700;
    letter-spacing: 0.045em;
    -webkit-font-smoothing: antialiased;
    text-transform: capitalize;
  }
  .MuiInput-root {
    margin-top: 23px;
    border: 1px solid #707070;
    @include border-radius(5px);
    padding: 2px 10px;
    height: 38px;
    @include animate-css(all 0.2s linear);
    &:after,
    &:before {
      border: none !important;
    }
    .MuiIconButton-root {
      padding: 10px 5px;
      &:focus {
        outline: none;
      }
    }
  }
  .MuiInput-root:hover,
  .MuiInput-root:focus {
    border-color: $PrimaryColor;
  }
  .MuiInput-input {
    color: #000;
    font-size: 12px;
    height: 1.1875em;
    box-sizing: content-box;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  .MuiInput-inputMultiline {
    min-height: 16px !important;
    max-height: 65px !important;
    padding: 6px 0 7px;
    overflow: auto !important;
    resize: vertical !important;
  }
  .Mui-disabled {
    .MuiInput-input {
      color: #545955;
    }
    &::before {
      border-bottom-width: 0px;
    }
  }
  .MuiFormLabel-root.small-label {
    font-size: 14px !important;
    margin-bottom: 12px;
    span {
      color: $ErrorColor;
    }
  }
  legend {
    width: 60px;
  }
  .input-text {
    color: #000;
    font-size: 16px;
  }
  .sub-radio {
    margin-top: 10px;
    font-size: 13px;
    overflow: hidden;
    label {
      margin: 0px;
    }
    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
    }
    .MuiCheckbox-root {
      padding: 0px 4px 0px 0px;
      position: relative;
    }
    .Mui-checked {
      .MuiSvgIcon-root {
        color: $PrimaryColor;
      }
      &:hover {
        background-color: rgba(39, 68, 99, 0.08);
      }
    }
    .MuiFormControlLabel-label {
      font-size: 13px;
      font-weight: 400;
      padding-top: 2px;
      color: #545955;
      position: relative;
      left: -3px;
    }
  }
  .set-sub-radio {
    position: relative;
    left: -10px;
  }
  .MuiSelect-select {
    margin-top: 3px;
  }

  .Table-select {
    width: 140px !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }
  @media (max-width: 591px) {
    width: 100%;
    .MuiTextField-root,
    .MuiInput-input {
      width: 100% !important;
    }
  }
  .MuiInputAdornment-positionStart {
    margin-right: 6px;
  }
}
/*** Styling Form Elements end ***/
  .cndt-row .mui-custom-form.with-select .MuiInput-root,
    .input-mt-0 .input-md,
    .mui-clr-mrgn .mui-custom-form,
    .set-no-margin .MuiInput-root,
    .search-form .makeStyles-container2-151,
    .no-mt .MuiInput-root,
    .textfield-margin-zero .MuiInput-root,
    .bill-prov-type .MuiInput-root,
    .page-header + div,
    .input-mt-0.set-no-margin {
        margin-top: 0 !important;
    }
/*--Buttons CSS start--*/
.btn {
    color: white !important;
    font-weight: 600 !important;
    font-size: 0.85rem !important;
    padding: 8px 15px !important;
    border-width: 1px !important;
    @include border-radius(5px !important);
    @include boxShadow(6px 3px 12px rgba(39, 68, 99, 0.3) !important);
    text-transform: uppercase;
    position: relative;
    text-decoration: none !important;
    .fa {
      font-size: 12px;
      margin-right: 5px;
    }
  }
  .btn-primary,
  .btn-search,
  .btn-print,
  .btn-cancel,
  .btn-validate,
  .btn-copy,
  .btn-refresh-upd,
  .btn-filter,
  .btn-maintenance,
  .btn-view-find,
  .btn-unlock,
  .btn-activate,
  .btn-view-primary,
  .btn-deactivate {
    background-color: $PrimaryColor !important;
    border-color: $PrimaryColor !important;
    &:hover,
    &:focus {
      background-color: #2f5b8a !important;
    }
  }
  
.btn-success,
.btn-save,
.btn-add,
.btn-update, .btn-replace, .btn-transfer {
  background-color: #006e62 !important;
  border-color: #005a64 !important;
  &:hover,
  &:focus {
    background-color: #005a64 !important;
  }
}
.btn-save.disabled,
.btn-save:disabled,
.btn-reset.disabled,
.btn-reset:disabled,
.btn-delete:disabled,
.btn-unlock:disabled,
.btn-activate:disabled,
.btn-deactivate:disabled
.btn-transfer.disabled,
.btn-transfer:disabled {
  color: #545955 !important;
  background-color: #dadddc !important;
  border-color: #aaafb9 !important;
  &:hover,
  &:focus {
    background-color: #dadddc !important;
  }
}

.btn-secondary,
.btn-help {
  background-color: $SecondaryColor !important;
  border-color: $SecondaryColor !important;
  &:hover,
  &:focus {
    background-color: #0a50c1 !important;
  }
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff !important;
  background-color: #cacdcb !important;
  border-color: #cacdcb !important;
}
.btn-transparent,
.btn-delete,
.btn-view,
.btn-notes,
.btn-reset,
.btn-audit,
.btn-filter-transparent {
  background-color: #fff !important;
  border: solid 1px $PrimaryColor !important;
  color: $PrimaryColor !important;
  &:hover,
  &:focus {
    background-color: #f8f8f8 !important;
  }
}
.btn-transparent.disabled,
.btn-transparent:disabled,
.btn-transparent-noborder.disabled,
.btn-transparent-noborder:disabled {
  color: #6c757d !important;
  background-color: #fff !important;
  border-color: #cacdcb !important;
  .fa {
    color: #cacdcb !important;
  }
}
.btn-transparent-noborder {
  background-color: #fff !important;
  color: $PrimaryColor !important;
  &:hover,
  &:focus {
    background-color: #f8f8f8 !important;
  }
}

.btn-audit,
.btn-notes {
  &.selected {
    background: $PrimaryColor !important;
    color: white !important;
  }
}
.btn-reset {
  border-color: #ffffff !important;
  padding-left: 30px !important;
  &:hover,
  &:focus {
    background-color: #f2f2f2 !important;
  }
  i {
    display: none;
  }
}

.btn-icon-only-add,
.btn-icon-only-save,
.btn-icon-only-reset,
.btn-icon-only-cancel,
.btn-icon-only-delete {
  background-color: #fff !important;
  border: solid 0px $PrimaryColor !important;
  color: $PrimaryColor !important;
  box-shadow: none !important;
  &:hover,
  &:focus {
    background-color: #f8f8f8 !important;
  }
}
.btn-icon-only-shuffle,
.btn-icon-only-info,
.btn-icon-only-qst {
  background-color: #fff !important;
  border: solid 0px $PrimaryColor !important;
  color: #0047ba !important;
  box-shadow: none !important;
  &:hover,
  &:focus {
    background-color: #fff !important;
  }
}
.btn-icon-only-info,
.btn-icon-only-qst {
  color: $PrimaryColor !important;
  line-height: 1 !important;
}
.btn-ic-2:before {
  font-size: 1.3rem !important;
}
.btn.btn-sm {
  padding: 3px 7px !important;
}
.th-btnGroup.mr-3 {
  margin-right: 16px !important;
}
.th-btnGroup .btn {
  margin-left: 5px;
}
.btn-ic {
  padding: 8px 15px 8px 30px !important;
}
.btn-ic-only {
  padding: 15px 20px 15px 23px !important;
}
.btn-ic-only-sm {
  padding: 7px 6px 7px 23px !important;
}
.btn-icon {
  padding: 8px 10px 8px 30px !important;
}
.btn-ic,
.btn-icon {
  [class^='fa-'] {
    display: none;
  }
}
.btn:before,
.btn-add-select:after {
  font-family: 'FontAwesomeIcon';
  font-size: 0.83rem;
  font-weight: 400;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
}
.btn-add-select:after {
  left: auto;
  right: 12px;
}
.btn-icon:before {
  font-size: 1.1rem !important;
}
.btn-save:before,
.btn-update:before {
  content: '\f00c';
}
.btn-add:before {
  content: '\f067';
}
.btn-add-select {
  padding-right: 30px !important;
}
.btn-add-select:after {
  content: '\f107';
  font-size: 1.2rem;
}
.btn-menu-child {
  border-bottom: 1px solid #000000;
  padding: 0 5px 0 5px !important;
}
.btn-search:before {
  content: '\f002';
}
.btn-delete:before {
  content: '\f1f8';
  font-size: 0.9rem;
}
.btn-reset:before {
  content: '\f0e2';
}
.btn-help:before {
  content: '\f059';
  font-size: 0.9rem;
}
.btn-print:before {
  content: '\f02f';
}
.btn-refresh-upd:before {
  content: '\f021';
}
.btn-notes:before {
  content: '\f15c';
}
.btn-view:before,
.btn-view-find:before,
.btn-view-primary:before {
  content: '\f06e';
}
.btn-validate:before {
  content: '\f00c';
}
.btn-filter:before {
  content: '\f0b0';
  font-size: 1rem !important;
}
.btn-copy:before {
  content: '\f24d';
}
.btn-audit:before {
  content: '\f274';
  font-size: 0.9rem;
  font-weight: 600;
}
.btn-replace:before {
  content: '\f0ec';
}
.btn-maintenance:before {
  content: '\f0ad';
}
.btn-icon-only-add:before {
  content: '\f067';
  font-size: 1.9rem;
  color: $SuccessColor !important;
}
.btn-icon-only-save:before {
  content: '\f00c';
  font-size: 1.9rem;
  color: $SuccessColor !important;
}
.btn-icon-only-reset:before {
  content: '\f0e2';
  font-size: 1.6rem;
}
.btn-icon-only-cancel:before {
  content: '\f00d';
  font-size: 1.9rem;
}
.btn-icon-only-delete:before {
  content: '\f1f8';
  font-size: 1.9rem;
}
.btn-icon-only-shuffle:before {
  content: '\f074';
  font-size: 0.9rem;
}
.btn-transfer:before {
  content: '\f362';
}
.pos-rel {
  position: relative;
  .btn-icon-only-info,
  .btn-icon-only-qst {
    position: absolute;
    right: 0;
    z-index: 1;
  }
}
.btn-icon-only-info:before {
  content: '\f05a';
  font-size: 0.9rem;
}
.btn-icon-only-qst:before {
  content: '\f059';
  font-size: 0.9rem;
}
.btn-unlock:before {
  content: '\f13e';
  font-size: 1.2rem;
  top: 55%;
}
.btn-activate:before {
  content: '\f00c';
}
.btn-deactivate:before {
  content: '\f05e';
  font-size: 1rem;
  color: #fff !important;
}
.cndt-icon {
  font-family: 'FontAwesomeIcon';
  display: inline-block;
  padding: 2px;
  font-size: 14px !important;
  &.ic-save:before,
  &.ic-check:before {
    content: '\f00c';
    color: $SuccessColor;
  }
  &.ic-close:before {
    content: '\f00d';
    color: $PrimaryColor;
  }
  &.ic-cross:before {
    content: '\f00d';
    color: $ErrorColor;
  }
  &.ic-reset:before {
    content: '\f0e2';
    color: $PrimaryColor;
  }
  &.ic-only {
    font-size: 1.2rem !important;
    line-height: 1;
    font-weight: normal;
  }
}
.fm-inline-btn {
  margin-top: 23px !important;
  .btn {
    min-height: 37px;
  }
}

  /*Buttons CSS end*/
  .view-more-btn {
    box-shadow: none !important;
    color: $PrimaryColor !important;
    font-size: 12px !important;
    text-transform: capitalize;
    text-decoration: underline !important;
  }
  /* custome table start */

  /* custome table end */
}


@media screen and (max-width: 1200px) {
  .form-wrapper .mui-custom-form, .form-wrapper .custom-form-wrapp-inner {
      width: calc(33.33% - 20px) !important;
      max-width: calc(33.33% - 20px) !important;
  }
  }
  
  @media only screen and (max-width: 1200px) and (min-width: 1025px) {
  .leftNavOff .tabs-container > div:not(.clm-inquiry-tabData) .form-wrapper .mui-custom-form, 
  .leftNavOff .tabs-container > div:not(.clm-inquiry-tabData) .form-wrapper .custom-form-wrapp-inner {
      width: calc(25% - 20px) !important;
      max-width: calc(25% - 20px) !important;
  }
  }
  
  @media screen and (max-width: 768px) {
  .form-wrapper .mui-custom-form {
      width: calc(50% - 20px) !important;
      max-width: calc(50% - 20px) !important;
  }
  }