@import './Variables.scss';
/*--Mixins Starts Here--*/
@mixin border-radius($val) {
    border-radius: $val;
    -webkit-border-radius: $val;
    -ms-border-radius: $val;
  }
  @mixin boxShadow($val) {
    box-shadow: $val;
    -webkit-box-shadow: $val;
    -ms-box-shadow: $val;
  }
  @mixin animate-css($props) {
    transition: $props;
    -webkit-transition: $props;
    -moz-transition: $props;
  }
/*--Mixins End Here--*/
.left-NavBtn {
    padding: 4px 6px !important;
    min-height: 35px;
}

/*  ---- Left Nav CSS starts here  ----  */
.full-menu .side-drawer {
  width: 0px !important;
}


/**Side Navigation**/
.navbar-blue-bg,
.left-bottom-logo {
  &.bottom {
    position: relative;
    left: 0px;
    bottom: 0px;
    justify-content: center;
    min-height: auto;
    width: 100%;
    border-bottom-width: 0px !important;
    border-top: 2px solid #fff;
    padding: 6px 0px 0px;
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    img {
      width: 160px;
      height: auto;
    }
    .sidemenu-heading {
      font-size: 14px;
    }
  }
}
.scollnavBar > div {
  overflow: initial !important;
}
.side-drawer {
  .MuiDrawer-paper {
    position: fixed !important;
  }
  div, main, section, article {
    max-width: 100%;
  }
}

.footer-text {
  font-size: 0.75em;
  text-align: center;
  color: rgb(84, 89, 85);
  opacity: 0.7;
}
.menu-close-button .MuiSvgIcon-root {
  font-size: 24px !important;
  position: relative;
  left: 5px;
}
.activeNav {
  background-color: $PrimaryColor !important;
  color: #ffffff !important;
  @include border-radius(5px);
}

.side-menu-list {
  .MuiSvgIcon-root {
    color: #545955 !important;
    font-size: 19px;
  }
  .MuiTypography-root {
    color: #545955 !important;
    font-size: 0.95rem;
    a {
      text-decoration: none !important;
    }
  }
  .MuiListItemIcon-root {
    min-width: 45px;
  }
  .sideNav-Link {
    display: block;
    &:hover {
      background-color: #dadddc !important;
    }
  }
  .activeNavLi {
    a,
    a:hover {
      background-color: $PrimaryColor !important;
      @include border-radius(5px);
      span,
      div {
        color: #ffffff !important;
        text-decoration: none !important;
      }
    }
  }
  & > .MuiListItem-gutters,
  .dashBoard {
    @extend .left-NavBtn;
  }
  & & .menu-level-3 {
    .sideNav-Link:hover {
      background-color: #f4f4f4 !important;
      @include border-radius(5px);
    }
    .sideNav-Link {
      padding-left: 40px !important;
      padding-right: 10px !important;
      &:hover,
      &:hover * {
        color: $PrimaryColor !important;
        text-decoration: none;
      }
      .MuiListItemText-root {
        padding-right: 0 !important;
      }
    }
    .sideNav-Link:after {
      left: 32px;
    }
  }
  & > .MuiListItem-gutters,
  &.dashBoard {
    .material-icons {
      position: relative;
      left: -3px;
      color: #aaafb9;
    }
  }
  & > .MuiListItem-gutters:hover *,
  .dashBoard:hover * {
    text-decoration: none;
  }
  &:list-child {
    height: 100%;
  }
  .MuiCollapse-wrapperInner {
    margin-bottom: 3px;
    .sideNav-Link,
    .MuiListItem-button {
      padding-left: 35px !important;
      cursor: pointer;
      .MuiListItem-gutters {
        padding-left: 0 !important;
      }
      .MuiSvgIcon-root {
        font-size: 19px;
      }
      .MuiListItemIcon-root {
        min-width: 38px;
      }
      .MuiTypography-root {
        font-size: 0.9rem;
      }
      &:hover {
        .MuiSvgIcon-root,
        .material-icons {
          color: $PrimaryColor !important;
        }
      }
      &.activeNav {
        &:hover {
          .MuiSvgIcon-root,
          .MuiTypography-root,
          .material-icons {
            color: #ffffff !important;
          }
        }
      }
    }
  }
}
.MuiList-root.menu-level-3 {
  padding: 0 2px;
}
#sideNav {
    .dashBoard {
        @extend .left-NavBtn;
    }
    .side-menu-icon {
        width: 30px;
        text-align: center;
    }
    .fa {
        font-size: 20px;
        color: #aaafb9;
    }
    .MuiListItemText-root {
        padding: 0px 8px !important;
        margin: 0 !important;
        cursor: pointer;
    }
}

    .activeNav,
    .activeNav:hover {
    width: 100%;
    display: inline-flex;
    align-items: center;
    padding-left: 15px;
    @include border-radius(5px !important);
    position: relative;
    }

    .activeNav *,
    .activeNav:hover *,
    .side-menu-list .activeNav .MuiTypography-root,
    .side-menu-list.activeNav .MuiTypography-root {
    color: #ffffff !important;
    }
    .activeNav:after {
    content: '';
    position: absolute;
    right: 0px;
    top: 3px;
    height: calc(100% - 6px);
    width: 4px;
    background: #ff8700;
    @include border-radius(4px);
    }

.MuiList-root {
  position: relative;
}
.MuiIcon-root {
  width: 1.3em !important;
  height: 1.3em !important;
  font-size: 1.7rem !important;
  text-align: center;
  line-height: 1.3 !important;
  text-indent: 0 !important;

  & & .sideNav-Link:after {
    content: '';
    position: absolute;
  }
}
.MuiExpansionPanel-root .MuiCollapse-container {
  @include animate-css(all 0.45s cubic-bezier(0, 0.175, 1) !important);
}
.side-drawer,
.MuiDrawer-paper,
.main-content,
.top-navbar {
  transition: none !important;
  transition-timing-function: none !important;
}
.clm-inquiry-tabData,
.clm-inquiry-exceptionData {
  transition: width 150ms !important;
  transition-timing-function: cubic-bezier(0.1, 0.4) !important;
}
.left-bottom-brand {
  display: inline-block;
}
.leftNavOff {
  .main-content {
    width: calc(100% - 75px);
  }
  .side-drawer,
  .MuiDrawer-paper {
    width: 75px !important;
  }
  .side-drawer {
    overflow-y: auto;
  }
  .MuiDrawer-paper {
    .MuiListItemText-root,
    .MuiSvgIcon-root {
      display: none;
    }
  }
  .side-drawer .MuiPaper-root {
    height: calc(100% - 90px);
    padding: 0;
  }
  .left-bottom-logo {
    &.bottom {
      position: fixed;
      width: 75px;
      .poweredBy {
        display: none;
      }
      .left-bottom-brand {
        width: 45px;
        overflow: hidden;
        position: relative;
        height: 45px;
        img {
          position: absolute;
          top: 0;
          right: -5px;
        }
      }
    }
  }
  .activeNav {
    &:after {
      right: -17px;
    }
  }
  #sideNav {
    overflow: initial;
    overflow-y: auto;
    padding-right: 15px;
    .MuiCollapse-wrapper {
      display: none;
    }
  }
  .MuiList-padding,
  .db-space {
    padding-right: 2px !important;
  }
}
.menu-level-3 .MuiListItemText-root .MuiTypography-root {
  padding-left: 15px;
}
/**----**/

/*--Side Navigation CSS--*/
#sideNav::-webkit-scrollbar,
.leftNavOff .MuiDrawer-paper::-webkit-scrollbar,
.rec-scroll::-webkit-scrollbar,
.slim-scroll::-webkit-scrollbar,
.MuiInput-formControl.MuiInput-multiline
  .MuiInput-inputMultiline::-webkit-scrollbar {
  width: 6px;
}
#sideNav::-webkit-scrollbar-track,
.leftNavOff .MuiDrawer-paper::-webkit-scrollbar-track,
.rec-scroll::-webkit-scrollbar-track,
.slim-scroll::-webkit-scrollbar-track,
.MuiInput-formControl.MuiInput-multiline
  .MuiInput-inputMultiline::-webkit-scrollbar-track {
  background: #bbbbbb;
  @include border-radius(5px);
}
/* Handle */
#sideNav::-webkit-scrollbar-thumb,
.leftNavOff .MuiDrawer-paper::-webkit-scrollbar-thumb,
.rec-scroll::-webkit-scrollbar-thumb,
.slim-scroll::-webkit-scrollbar-thumb,
.MuiInput-formControl.MuiInput-multiline
  .MuiInput-inputMultiline::-webkit-scrollbar-thumb {
  background: #999999;
  @include boxShadow(inset 0 0 6px #666666);
  @include border-radius(5px);
  z-index: 99;
}
/* Handle on hover */
#sideNav::-webkit-scrollbar-thumb:hover,
.leftNavOff .MuiDrawer-paper::-webkit-scrollbar-thumb:hover,
.rec-scroll::-webkit-scrollbar-thumb:hover,
.slim-scroll::-webkit-scrollbar-thumb:hover,
.MuiInput-formControl.MuiInput-multiline
  .MuiInput-inputMultiline::-webkit-scrollbar-thumb:hover {
  background: #666666;
  @include border-radius(5px);
}
#sideNav {
  scrollbar-color: #666666 #999999;
  scrollbar-width: thin;
  @include border-radius(5px);
  padding-left: 5px;
}
.App-logo {
  height: 40vmin;
}
.full-menu .side-drawer {
  width: 0px !important;
}
.side-drawer {
  .MuiPaper-root {
    padding: 0 0 10px;
    background: none !important;
    z-index: 9 !important;
    top: 22px !important;
    height: calc(100% - 20px);
    overflow-y: hidden;
  }
}
.scollnavBar {
  height: calc(100% - 25px);
}
.MuiList-padding,
.db-space {
  padding: 0 5px 5px !important;
}
.poweredBy {
  font-size: 12px;
  font-weight: 600;
  padding-top: 4px;
  padding-left: 10px;
}
.makeStyles-drawerPaper-7,
.side-drawer {
  width: 245px !important;
}
.nav-list-container {
  height: calc(100% - 2px);
  overflow-y: auto;
  .MuiList-root {
    margin-top: 2px !important;
  }
  .MuiListItem-root {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
   }
}

.leftNavOff {
  .main-content {
    width: calc(100% - 75px);
  }
  .side-drawer,
  .MuiDrawer-paper {
    width: 75px !important;
  }
  .side-drawer {
    overflow-y: auto;
  }
  .MuiDrawer-paper {
    .MuiListItemText-root,
    .MuiSvgIcon-root {
      display: none;
    }
  }
  .side-drawer .MuiPaper-root {
    height: calc(100% - 90px);
    padding: 0;
  }
  .left-bottom-logo {
    &.bottom {
      position: fixed;
      width: 75px;
      .poweredBy {
        display: none;
      }
      .left-bottom-brand {
        width: 45px;
        overflow: hidden;
        position: relative;
        height: 45px;
        img {
          position: absolute;
          top: 0;
          right: -5px;
        }
      }
    }
  }
  .activeNav {
    &:after {
      right: -17px;
    }
  }
  #sideNav {
    overflow: initial;
    overflow-y: auto;
    padding-right: 15px;
    .MuiCollapse-wrapper {
      display: none;
    }
  }
  .MuiList-padding,
  .db-space {
    padding-right: 2px !important;
  }
}

@media screen and (max-width: 1024px) {
  /*-- Left Navigation --*/
  .side-drawer {
    position: fixed;
    left: 0;
    height: 100%;
    display: flex;
    z-index: 111;
    background: #f4f4f4;
    width: 245px !important;
    @include boxShadow(0 3px 10px rgba(0, 0, 0, 0.3));
    .leftNavOff & {
      width: 75px !important;
    }
  }
  .MuiDrawer-paper {
    width: 245px !important;
    .MuiListItemText-root,
    .MuiSvgIcon-root {
      display: block !important;
      .leftNavOff & {
        display: none !important;
      }
    }
    .leftNavOff & {
      width: 75px !important;
    }
  }
}
.build-version {
  color: #545955;
  font-size: 0.713em;
  padding-left: 12px;
  height: 25px;
  span a {
    color: #0047ba !important;
    text-decoration: none !important;
    cursor: pointer;
    font-weight: 700;
  }
}